import "../css/navbar.css"
import { Link } from 'react-router-dom'
import { AuthContext } from './authContext'
import React, { useContext } from 'react'
import logo from "../img/logo.png"
import merlin from "../img/merlin.png"
import { FaPaperPlane } from "react-icons/fa"


export default function NavBar() {
    const { currentUser } = useContext(AuthContext);

    return (
        <div className="navbar-top">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </div>
            <div className="navbar-active">
                <Link className="navbar-link" to="/blogs">
                    <h6>Blog</h6>
                </Link>
                <Link className="navbar-link" to="/photos">
                    <h6>Photos</h6>
                </Link>
                {currentUser && currentUser === "admin" && <Link className="navbar-link" to="/write"><h6>Write</h6></Link>}
                {currentUser && currentUser === "admin" && <Link className="navbar-link" to="/upload"><h6>Upload Photo</h6></Link>}
            </div>
            <div className="merlin-pic">
                <Link to="/merlin"><img src={merlin} alt="" /></Link>
            </div>
            <div className="navbar-right">
                <div className="subscribe-bar">
                    <Link className="subscribe-button" to="/subscription">Subscribe</Link>
                </div>
                <div className="contact-me">
                    <Link className="letter-icon" to="/contact"><FaPaperPlane className="icon" /></Link>
                </div>
            </div>
        </div>
    )
}