import { createContext, useEffect, useState } from "react";
import axios from "axios"

export const AuthContext = createContext()

export const AuthContextProvider = ({children}) =>{
    // CREATE STATE VARIABLE AND GET CURRENT USER FROM LOCAL STORAGE
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user") || null));

    const authLogin = async(inputs) =>{
        const res = await axios.post("/auth/login", inputs);
        setCurrentUser(res.data);
    };
    // RESET LOCAL STORAGE'S USER VARIABLE TO CURRENTUSER WHEN IT CHANGES
    useEffect(()=>{
        localStorage.setItem("user", JSON.stringify(currentUser))
    }, [currentUser])

    return (
        // PASS ON CURRENTUSER AND LOGIN FUNCTION TO CHILDREN WHICH IS APP
        <AuthContext.Provider value={{currentUser, authLogin}}>
            {children}
        </AuthContext.Provider>
    )
}