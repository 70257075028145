import "../css/single.css"
import React, {useState, useContext, useEffect} from 'react';
import { AuthContext } from "../components/authContext"
import {Link, useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import DOMPurify from "dompurify";


export default function Single(){

    const [post, setPost] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    const postId = location.pathname.split("/")[2];
    const {currentUser} = useContext(AuthContext);

    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                const res = await axios.get(`/posts/${postId}`)
                setPost(res.data)
            }catch(error){
                if (error.response.status === 404) {
                    navigate("/404");
                }else{
                    console.error(error)
                }
            }
        };
        fetchData();
    },[postId]);

    const handleDelete = async ()=>{
        try{
            await axios.delete(`/posts/${postId}`)
            navigate("/")
        }catch(err){
            console.error(err.response.data)
        }
    }

    const getText = (html) =>{
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
      }

    const shortDate = post.date ? post.date.substring(0,10) : "";
    

    return ( 
        <div className='single'>
                {currentUser && currentUser === "admin" && (
                    <div className='single-buttons'>
                        <Link className="single-link" to={`/write?edit=2`} state={post}>
                            <button className="single-edit">Edit</button>
                        </Link>
                        <button className='single-delete'onClick={handleDelete}>Delete</button>
                    </div>  
                )}
            <p>Published {getText(shortDate)}</p>
            <div className='single-content'>
                <h1>{post.title}</h1>
            <p
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(post.value),
                }}
            ></p>    
            </div>     
        </div>
     );
}