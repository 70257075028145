import React from 'react'
import "../css/notfound.css"

export default function NotFound() {
  return (
    <div className='not-found'>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
    </div>
  )
}
