import "../css/upload.css"
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Upload () {

    const state = useLocation().state;
    const [file, setFile] = useState(null);
    const [desc, setDesc] = useState(state?.desc || "");

    const navigate = useNavigate();

    const upload = async () =>{
        if (file !== null){
            try{
                const formData = new FormData();
                formData.append("file", file)
                const res = await axios.post("/upload", formData);
                return res.data;
            }catch(err){
                console.log(err)
            }
        }
    }

    const handleClick = async (e) =>{
        e.preventDefault();
        const imgUrl = await upload();
        try{
            await axios.post(`/photos`, {
                    desc:desc,
                    img: imgUrl,
                    date: moment(Date.now()).format("YYYY-MM-DD"),
                });
                navigate("/photos")          
        }catch(error){
            console.log("Can't upload photo")
            console.error(error.response.data)
        }
    };

    return (
        <div className="add-photo">
            <input type="file" name="" id="file" onChange={e=>setFile(e.target.files[0])}/>
            <label htmlFor="file">Upload Image</label>
            <input className='add-photo-text'type="text" placeholder="Description" value={desc} onChange={e=>setDesc(e.target.value)}/>
            <button onClick={handleClick}>Upload</button>
        </div>
    )
}
