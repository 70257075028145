import "../css/merlin.css"
import olsen from "../img/olsen.jpg"

export default function Merlin() {

    return (
        <div className="merlin">
            <h1>Merlin's Mission</h1>
            <div className="merlin-content">
                <img src={olsen} alt=""></img>
                <div className="merlin-text">
                    <p>Merlin Olsen played 15 years in the National Football League as a defensive tackle for the Los Angeles Rams. 
                        14 of those 15 years he was selected to the Pro Bowl as one of the NFL's outstanding
                        players. 6 of those 15 years he was selected to the First-Team All-Pro as one of the very best. 
                        He was selected to the NFL's All-Decade Team for the 1960s and 1970s.</p>
                    <p>Here is Merlin Olsen's Mission Statement:</p>
                    <div className="merlin-mission">
                        <p>The focus of my life begins at home with family, loved ones and friends. 
                            I want to use my resources to create a secure environment that fosters love, learning, laughter and mutual success.</p>
                        <ul>
                            <li>I will protect and value integrity.</li>
                            <li>I will admit and quickly correct my mistakes.</li>
                            <li>I will be a self-starter.</li>
                            <li>I will be a caring person.</li>
                            <li>I will be a good listener with an open mind.</li>
                            <li>I will continue to grow and learn.</li>
                            <li>I will facilitate and celebrate the success of others.</li>
                        </ul>
                    </div>
                </div>  
            </div>
        </div>
    )
}
