import "../css/verify.css"
import axios from "axios";
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Verify() {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const code = location.pathname.split("/")[2];

    useEffect(()=> {
        const updateVer = async () => {
            try{
                await axios.patch(`/subs/${code}`, {
                    verified: 1,
                    code: code,
                });
                setMessage("Thank you. Email has been verified");
                setTimeout(() => {
                    navigate("/");
                }, 5000);
            }catch(error){
                if (error.response.status === 404) {
                    navigate("/404");
                }else{
                    console.error(error)
                }
            }
        };
        updateVer();
    }, [])

    return (
        <div className='verify'>
            {message && <h1>{message}</h1>}
        </div>
    )
}
