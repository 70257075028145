import "../css/write.css"
import React from 'react'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useState} from 'react'
import axios from "axios"
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment"

export default function WriteDraft(){
    
    const state = useLocation().state;
    const [value, setValue] = useState(state?.value || '');
    const [title, setTitle] = useState(state?.title ||'');
    const [desc, setDesc] = useState(state?.desc ||'');
    const [file, setFile] = useState(null);
    const [cat, setCat] = useState(state?.cat ||'');
    const [err, setErr] = useState(null)
    const id = state.id 

    const navigate = useNavigate();
    const checkRadios = document.querySelectorAll('input[type=radio]:checked')
    const upload = async ()=>{
        
        if (file !== null){
            try{
                const formData = new FormData();
                formData.append("file", file)
                const res = await axios.post("/upload", formData);
                return res.data;
                
            }catch(err){
                console.error(err.response.data)
            }
        }
    }

    const handleDraft = async (e) =>{
        e.preventDefault();
        const imgUrl = await upload();
    
        try{
            state 
                ? await axios.put(`/drafts/${state.id}`, {
                    title: title,
                    desc:desc, 
                    cat: cat, 
                    img: file ? imgUrl : "",
                    value: value,
                    }) 
                : await axios.post(`/drafts/`, {
                    title: title,
                    desc:desc, 
                    cat: cat, 
                    img:file ? imgUrl : "",
                    date: moment(Date.now()).format("YYYY-MM-DD"),
                    value: value,
                });
                navigate("/drafts")
        }catch(err){
            console.error(err.response.data)
        }
    };

    const sendEmails = async () => {
        try {
            await axios.post(`/subs/send`, {
                cat: cat
            });
            console.log(cat)
        }catch(err){
            console.log(err.response.data)
        }
      }

    const handleDelete = async ()=>{
        try{
            await axios.delete(`/drafts/${id}`)
        }catch(err){
            console.error(err.response.data)
        }
    }
        
    const handleClick = async (e) =>{
        e.preventDefault();
        const imgUrl = await upload();
        
        try{
            if (checkRadios.length > 0) {
                await axios.post(`/posts/`,{
                    title: title,
                    desc:desc, 
                    cat: cat, 
                    img:file ? imgUrl : "",
                    date: moment(Date.now()).format("YYYY-MM-DD"),
                    value: value,
                });
                sendEmails();
                handleDelete();
                navigate("/");
            }else{
                setErr("Choose at least one category")
            }
        }catch(err){
            console.error(err.response.data)
        }
    };
    
    return ( 
        <div className='write'>
            <div className='write-content'>
                <input type="text" value={title} placeholder='Title' onChange={e=>setTitle(e.target.value)}/>
                <input type="text" value={desc} placeholder='Description' onChange={e=>setDesc(e.target.value)}/>
                <div className='editorContainer'>
                    <ReactQuill 
                        className="editor" 
                        theme="snow" 
                        value={value} 
                        onChange={setValue}
                        modules={WriteDraft.modules}
                        formats={WriteDraft.formats} />
                </div>
            </div>
            <div className='write-menu'>
                <div className='write-item'>
                    <label htmlFor="file">Upload Image</label>
                    <input type="file" name="" id="file" onChange={e=>setFile(e.target.files[0])}/>
                    <div className='write-category'>
                        <h1>Category</h1>
                        <input type='radio' checked={cat === 'mylife'} name='cat' value='mylife' id='mylife' onChange={(e) => setCat(e.target.value)}/>
                        <label htmlFor='mylife'>My Life</label>
                        <input type='radio' checked={cat === 'ourworld'} name='cat' value='ourworld' id='ourworld' onChange={(e) => setCat(e.target.value)}/>
                        <label htmlFor='ourworld'>Our World</label>
                    </div>
                    <div className='write-buttons'>
                        <button onClick={handleDraft}>Save as a Draft</button>
                        <button onClick={handleClick}>Publish</button>
                    </div>
                    {err && <p className="write-error">{err}</p>}
                </div>
            </div>
        </div>
     );
}

WriteDraft.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "+1" }, { indent: "-1" }],
        [{ align: [] }],
        [{ size: ["small", "large", "huge", false] }],
        ["link", "video", "formula"],
        [{ color: [] }, { background: [] }],
        ['image']
    ],
};

WriteDraft.formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "script",
    "indent",
    "align",
    "size",
    "link",
    "video",
    "color",
    "background",
    "image"
];
