import "../css/login.css"
import React from 'react'
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../components/authContext'

export default function Login() {
  const [inputs, setInputs] = useState({
    password:""
  });

  const [err, setError] = useState(null);
  const navigate = useNavigate();
  //TAKE AUTHLOGIN FUNCTION FROM AUTHCONTEXT.JS
  const { authLogin } = useContext(AuthContext);

  const handleChange = (e) => {
    setInputs(prev => ({ ...prev, [e.target.name]: e.target.value}));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      await authLogin(inputs)
      navigate("/")
    }catch(err){
      setError(err.response.data);
    }
  }

  return (
    <div className="auth">
      <form className="auth-form">
        <input required type="password" placeholder="Password" name="password" onChange={handleChange}/>
        <button onClick={handleSubmit}>ENTER</button>
        {err && <p>{err}</p>}
      </form>
    </div>
  )
}
