import "../css/photo.css"
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../components/authContext"

export default function Photo () {
    const [photo, setPhoto] = useState({});
    const [photos, setPhotos] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const photoId = parseInt(location.pathname.split("/")[2]);
    const {currentUser} = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const photoRes = await axios.get(`/photos/${photoId}`);
                setPhoto(photoRes.data);
                const photosRes = await axios.get(`/photos/`);
                const photosObject = photosRes.data;
                setPhotos(photosObject.map(photo => photo.id));
            } catch (error) {
                if (error.response.status === 404) {
                    navigate("/404");
                } else {
                    console.error(error);
                }
            }
        };
        fetchData();
    }, [photoId]);
    

    const handleDelete = async () =>{
        try{
            await axios.delete(`/photos/${photoId}`)
            navigate("/photos")
        }catch(err){
            console.error(err.response.data)
        }
    }

    const getText = (html) =>{
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
      }

    const shortDate = photo.date ? photo.date.substring(0,10) : "";

    const navigateNext = () => {
        const currentIndex = photos.indexOf(photoId)
        const newIndex = currentIndex+1
        if (newIndex < photos.length) 
            navigate(`/photo/${photos[newIndex]}`)
    }

    const navigatePrevious = () => {
        const currentIndex = photos.indexOf(photoId)
        const newIndex = currentIndex-1
        if (newIndex > -1) 
            navigate(`/photo/${photos[newIndex]}`)
    }

    return (
        <div className="photo">
            <div className="photo-main">
                <div className="photo-navigate photo-previous">
                    <button onClick={navigatePrevious}>
                        &larr;
                    </button>
                </div>
                <div className="photo-content">
                    <img src={`../uploads/${photo.img}`} alt=""/>
                    <p>Published {getText(shortDate)}</p>
                    <p>{photo.desc}</p>
                </div>
                <div className="photo-navigate photo-next">
                    <button onClick={navigateNext}>
                        &rarr;
                    </button>
                </div>
            </div>
            {currentUser && currentUser === "admin" && (
                <div className="photo-delete">
                    <button onClick={handleDelete}>Delete</button>
                </div>
            )}
        </div>
    );
}
