import "../css/blog.css"
import React, { useEffect, useState, } from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";
import ReactPaginate from 'react-paginate';

export default function OurWorld() {

    const [posts, setPosts] = useState([]);

    //FETCH POSTS FROM MYSQL DB
    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                const res = await axios.get(`/posts${'?cat=ourworld'}`)
                setPosts(res.data)
            }catch(err){
                console.error(err.response.data)
            }
        };
        fetchData();
    },[]);
    
    //PARSE TEXT IN READABLE HTML FORMAT
    const getText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent
    }    

    // SET UP PAGINATION AND ORDER OF POSTS
    const [currentItems, setCurrentItems] = useState([]);
    const[pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 6;

    useEffect(()=> {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems([...posts].reverse().slice(itemOffset, endOffset));
        setPageCount(Math.ceil(posts.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, posts]);

    const handlePageClick = (e) => {
        const newOffset = (e.selected * itemsPerPage) % posts.length;
        setItemOffset(newOffset);
        };

    return (
        <>
            <div className='blog-home'>
                <div className='posts'>
                    {currentItems.map(post=>(
                        <div className='post' key={post.id}>
                            <div className='post-img'>
                                <img src={post.img ? `./uploads/${post.img}`: ""} alt="" />
                            </div>
                            <div className='blog-content'>
                                <Link className='post-link' to={`/post/${post.id}`}>
                                    <h1>{post.title}</h1>
                                </Link>
                                <p>{getText(post.desc)}</p>      
                            </div>
                        </div>
                    ))}
                </div>
                <ReactPaginate 
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="Previous"
                    renderOnZeroPageCount={null}
                    containerClassName="blog-pagination"
                    pageLinkClassName='blog-page-num'
                    previousLinkClassName="blog-page-num"
                    nextLinkClassName='blog-page-num'
                    activeLinkClassName='blog-page-active'
                /> 
            </div>
        </>
    );
}
