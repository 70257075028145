import React from 'react'
import "../css/blogs.css"
import me from "../img/me.jpg"
import world from "../img/world.jpg"
import { Link } from 'react-router-dom'

export default function Blogs() {
  return (
    <div className='blogs'>
      <div className='ourworld-blog'>
        <Link to="/ourworld">
            <img src={world} alt=""></img>
            <h6>Our World</h6>
        </Link>
      </div>
      <div className='mylife-blog'>
          <Link to="/mylife">
            <img src={me} alt=""></img>
            <h6>My Life</h6>
          </Link>   
      </div>
    </div>
  )
}
