import "../css/single.css"
import React, {useState, useContext, useEffect} from 'react';
import { AuthContext } from "../components/authContext"
import {Link, useLocation, useNavigate} from 'react-router-dom';
import moment from "moment";
import axios from 'axios';
import DOMPurify from "dompurify";

export default function SingleDraft () {

    const [draft, setDraft] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    const draftId = location.pathname.split("/")[2];
    const {currentUser} = useContext(AuthContext);

    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                const res = await axios.get(`/drafts/${draftId}`)
                setDraft(res.data)
            }catch(err){
                console.error(err.response.data)
            }
        };
        fetchData();
    },[draftId]);

    const handleDelete = async ()=>{
        try{
            await axios.delete(`/drafts/${draftId}`)
            navigate("/drafts")
        }catch(err){
            console.error(err.response.data)
        }
    }

    return ( 
        <div className='single'>
                {currentUser && currentUser === "admin" && (
                    <div className='single-buttons'>
                        <Link className="single-link" to={`/writedraft`} state={draft}>
                            <button className="single-edit">Edit</button>
                        </Link>
                        <button className='single-delete'onClick={handleDelete}>Delete</button>
                    </div>  
                )}
            <p>Published {moment(draft.date).fromNow()}</p>
            <div className='single-content'>
                <h1>{draft.title}</h1>
            <p
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(draft.value),
                }}
            ></p>    
            </div>     
        </div>
     );
}
