import "../css/managesub.css"
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ManageSub() {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [mylife, setMyLife] = useState(0);
    const [world, setWorld] = useState(0);

    const handleClick = () => {
        const fetchData = async () => {
            try{
                const res = await axios.get(`/subs/${email}`)
                setMyLife(res.data.mylife)
                setWorld(res.data.ourworld)
            }catch(err){
                console.error(err.response.data)
            }
        };
        fetchData();
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        
        try {
            await axios.put(`/subs/${email}`, {
              mylife: mylife,
              ourworld: world,
          });
            navigate("/"); 
        }catch(err){
            console.error(err.response.data)
        }
    }

    return (
        <div className="manage-sub">
            <h1>Manage Your Subscription</h1>
            <div className="manage-sub-content">
                <input className="manage-email" type="email" placeholder="write your email" onChange={(e) => setEmail(e.target.value)}></input>
                <button onClick={handleClick}>Show Subscriptions</button>
                <input className="manage-checkbox" type='checkbox' value={true} name='MyLife Blog' id='MyLife Blog' checked={mylife} onChange={e=>setMyLife(e.target.checked)}/>
                <label className="manage-checkbox" htmlFor='MyLife Blog'>My Life Blog</label>
                <input className="manage-checkbox" type='checkbox'value={true} name='Our World Blog' id='Our World Blog' checked={world} onChange={e=>setWorld(e.target.checked)}/>
                <label className="manage-checkbox" htmlFor='Our World Blog'>Our World Blog</label>
                <button onClick={handleUpdate}>Update Subscription</button>
            </div>
            
        </div>
    )
}