import "../css/photos.css"
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';


export default function Photos() {

    const [photos, setPhotos] = useState([]);

    useEffect(()=>{
        const fetchPhotos = async ()=>{
            try{
                const res = await axios.get("/photos")
                setPhotos(res.data)
            }catch(err){
                console.error(err.response.data)
            }
        };
        fetchPhotos();
    },[]);

    const [currentItems, setCurrentItems] = useState([]);
    const[pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 9;

    useEffect(()=> {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems([...photos].reverse().slice(itemOffset, endOffset));
        setPageCount(Math.ceil(photos.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, photos]);

    const handlePageClick = (e) => {
        const newOffset = (e.selected * itemsPerPage) % photos.length;
        setItemOffset(newOffset);
        };

    return (
        <div className="photos">
            <div className ="photo-grid">
            {currentItems.map(photo=>(
                <div className="photoImg" key={photo.id}>
                    <Link to={`/photo/${photo.id}`}>
                        <img src={`./uploads/${photo.img}`} alt=""/>
                    </Link>
                </div>
            ))}
            </div>
            <ReactPaginate 
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
                containerClassName="photos-pagination"
                pageLinkClassName='photos-page-num'
                previousLinkClassName="photos-page-num"
                nextLinkClassName='photos-page-num'
                activeLinkClassName='photos-page-active'
            /> 
        </div>
    )
}

    
