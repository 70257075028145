import "../css/home.css"
import React from 'react'
import Quotes from "./Quotes"

export default function Home() {
  return (
    <div className="home">
      <div className="quotes">
        <Quotes />
      </div>
    </div>
  )
}
