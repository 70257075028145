import "../css/quotes.css"
import React from 'react'

export default function Quotes() {
  const quoteArr = [
      { 
          quote: `This love is like the scent of a flower. It doesn't require that others behave a certain way. 
          It doesn't demand anything from others. It just gives without a desire for any compensation. The love itself is the compensation. This is the divine love that is hidden deep inside the silent heart.`,
          author: 'Unknown'
      },
      {
          quote: "What was said to the rose that made it open was said to me, here in my heart.",
          author: "Rumi"
      },
      {
          quote: `Government is not reason; it is not eloquence; it is force. Like fire, it is a dangerous servant and a fearful master.`,
          author: "George Washington"
      },
      {
          quote: `No man's life, liberty, or property are safe while the legislature is in session.`,
          author:"Gideon J. Tucker"
      },
      {
          quote: `A democracy cannot exist as a permanent form of government. It can only exist until a majority of voters discover that they can vote themselves largess out of the public treasury.`,
          author:"Alexander Fraser Tytler"
      },
      {
          quote: `Quiet the mind and the soul will speak.`,
          author: `Na Jaya Sati Bhagavati`
      },
      {
          quote:`Philanthropy is the gateway to power.`,
          author: `Mad Men`
      },
      {
        quote: `A good day starts with a good mindset.`,
        author: `Anonymous`
      },
      {
        quote: `The secret to getting ahead is getting started.`,
        author: `Mark Twain`
      },
      {
        quote: `Courage is resistance to fear, mastery of fear, not absence of fear.`,
        author: `Mark Twain`
      },
      {
        quote: `I have failed over and over again in my life. And that is why I succeed.`,
        author: `Michael Jordan`
      },
      {
        quote: `It is not whether you get knocked down; it is whether you get up.`,
        author: `Vince Lombardi`
      },
      {
        quote: `Never say never because limits, like fears, are often just an illusion.`,
        author: `Michael Jordan`
      },
      {
        quote: `One thing is clear: The Founding Fathers never intended a nation where citizens would pay nearly half of everything they earn to the government.`,
        author: `Ron Paul`
      },
      {
        quote: `Always stand on principle...even if you stand alone.`,
        author: `John Adams`
      },
      {
        quote: `Well done is better than well said.`,
        author: `Benjamin Franklin`
      },
      {
        quote: `If ye love wealth greater than liberty, the tranquility of servitude greater than the animating contest for freedom, go home from us in peace. We seek not your counsel, nor your arms. Crouch down and lick the hand that feeds you; May your chains set lightly upon you, and may posterity forget that ye were our countrymen.`,
        author: `Samuel Adams, speech to Second Continental Congress on August 1, 1776.`
      },
      {
        quote: `Those who can make you believe absurdities, can make you commit atrocities.`,
        author: `Voltaire`
      },
      {
        quote: `It is dangerous to be right in matters where established men are wrong.`,
        author: `Voltaire`
      },
      {
        quote: `So long as the people do not care to exercise their freedom, those who wish to tyrannize will do so; for tyrants are active and ardent, and will devote themselves in the name of any number of gods, religious and otherwise, to put shackles upon sleeping men.`,
        author: `Voltaire`
      },
      {
        quote: `A claim for equality of material position can be met only by a government with totalitarian powers.`,
        author: `Friedrich August von Hayek`
      },
      {
        quote: `We can evade reality, but we cannot evade the consequences of evading reality.`,
        author: `Ayn Rand`
      },
      {
        quote: `When plunder becomes a way of life, men create for themselves a legal system that authorizes it and a moral code that glorifies it.`,
        author: `Frederic Bastiat`
      },
      {
        quote: `The advocates of public control cannot do without inflation. They need it in order to finance their policy of reckless spending and of lavishly subsidizing and bribing the voters.`,
        author: `Ludwig von Mises`
      },
      {
        quote: `Historical knowledge is indispensable for those who want to build a better world`,
        author: `Ludwig von Mises`
      },
      {
        quote: `The causes of all panics, crashes and depressions can be summed up in only four words: the misuse of credit.`,
        author: `Ludwig von Mises`
      },
      {
        quote: `The real bosses, in the capitalist system of market economy, are the consumers. He who serves the public best, makes the highest profits.`,
        author: `Ludwig von Mises`
      },
      {
        quote: `Big-heartedness is the most essential virtue.`,
        author: `Matthew Fox`
      },
      {
        quote: `We are not human beings having a spiritual experience. We are spiritual beings having a human experience.`,
        author: `Pierre Teilhard de Chardin`
      },
      {
        quote: `Happiness cannot be traveled to, owned, earned, worn or consumed. Happiness is the spiritual experience of living every minute with love, grace, and gratitude.`,
        author: `Denis Waitley`
      },
      {
        quote: `Realize deeply that the present moment is all you have. Make the NOW the primary focus of your life.`,
        author: `Eckhart Tolle`
      },
      {
        quote: `We are an impossibility in an impossible universe.`,
        author: `Ray Bradbury`
      },
      {
        quote: `Within you there is a stillness and a sanctuary to which you can retreat at any time and be. `,
        author: `Hermann Hesse`
      },
      {
        quote: `Looking deeply at life as it is in this very moment, the meditator dwells in stability and freedom.`,
        author: `Buddha`
      },
      {
        quote: `There are only two ways to live your life. One is as though nothing is a miracle. The other is as though everything is.`,
        author: `Albert Einstein`
      },
      {
        quote: `No individual can ultimately fail. The Divinity which descends into humanity is bound to regain its original state.`,
        author: `N. Sri Ram`
      },
      {
        quote: `To forgive is to set a prisoner free and discover that the prisoner was you.`,
        author: `Lewis B. Smedes`
      },
      {
        quote: `The Way is not in the sky; the Way is in the heart.`,
        author: `Buddha`
      },
      {
        quote: `The bad news: There is no key to the universe. The good news: It was never locked.`,
        author: `Swami Beyondananda`
      },
      {
        quote: `Being spiritual has nothing to do with what you believe and everything to do with your state of consciousness.`,
        author: `Eckhart Tolle`
      },
      {
        quote: `This place is a dream. Only a sleeper considers it real. Then death comes like dawn, and you wake up laughing at what you thought was your grief.`,
        author: `Rumi`
      },
      {
        quote: `You are not a drop in the ocean. You are the entire ocean, in a drop.`,
        author: `Rumi`
      },
      {
        quote: `The Prophets accept all agony and trust it. For the water has never feared the fire.`,
        author: `Rumi`
      },
      {
        quote: `Out beyond ideas of wrongdoing and rightdoing there is a field. When the soul lies down in that grass the world is too full to talk about.`,
        author: `Rumi`
      },
      {
        quote: `Silence is the language of god, all else is poor translation.`,
        author: `Rumi`
      },
      {
        quote: `Above all, don't lie to yourself. The man who lies to himself and listens to his own lie comes to a point that he cannot distinguish the truth within him, or around him, and so loses all respect for himself and for others. And having no respect he ceases to love.`,
        author: `Fyodor Dostoevsky`
      },
      {
        quote: `Pain and suffering are always inevitable for a large intelligence and a deep heart. The really great men must, I think, have great sadness on earth.`,
        author: `Fyodor Dostoevsky`
      },
      {
        quote: `What is hell? I maintain that it is the suffering of being unable to love.`,
        author: `Fyodor Dostoevsky,`
      },
      {
        quote: `The mystery of human existence lies not in just staying alive, but in finding something to live for.`,
        author: `Fyodor Dostoyevsky`
      },
      {
        quote: `The best way to keep a prisoner from escaping is to make sure he never knows he's in prison.`,
        author: `Fyodor Dostoyevsky`
      },
      {
        quote: `Wrong does not cease to be wrong because the majority share in it.`,
        author: `Leo Tolstoy`
      },
      {
        quote: `All the variety, all the charm, all the beauty of life is made up of light and shadow.`,
        author: `Leo Tolstoy`
      },
      {
        quote: `Let your net always be cast, and in the pool which you least expect, there will be a fish.`,
        author: `Ovid`
      },
      {
        quote: `Courage is not the towering oak that sees storms come and go. It is the fragile blossom that opens in the snow.`,
        author: `Alice M. Swain`
      },
      {
        quote: `You can complain because roses have thorns, or you can rejoice because thorns have roses.`,
        author: `Zig Ziglar`
      },
      {
        quote: `Although the world is full of suffering it is also full of the overcoming of it.`,
        author: `Helen Keller`
      },
      {
        quote: `Labels are for cans, not people.`,
        author: `Anthony Rapp`
      },
      {
        quote: `Men in the game are blind to what men looking on see clearly.`,
        author: `Chinese Proverb`
      },
      {
        quote: `Darkness is only driven out with light, not more darkness.`,
        author: `Martin Luther King, Jr.`
      },
      {
        quote: `An eye for an eye makes the whole world blind.`,
        author: `Gandhi`
      },
      {
        quote: `It is our imagination that transforms itself into reality.`,
        author: `Helen Araromi`
      },
      {
        quote: `But the eyes are blind, one must look with the heart.`,
        author: `Antoine de Saint-Exupery`
      },
      {
        quote: `All truths are easy to understand once they are discovered. The point is, to discover them.`,
        author: `Galileo Galilei`
      },
      {
        quote: `When the student is ready the teacher will appear.`,
        author: `Buddhist Proverb`
      },
      {
        quote: `There is no shame in not knowing. The shame lies in not finding out.`,
        author: `Russian Proverb`
      },
      {
        quote: `The unexamined life is not worth living.`,
        author: `Socrates`
      },
      {
        quote: `The journey of a thousand miles begins with a single step.`,
        author: `Lao Tzu`
      },
      {
        quote: `If you are depressed you are living in the past. If you are anxious you are living in the future. If you are at peace you are living in the present.`,
        author: `Lao Tzu`
      },
      {
        quote: `Nature does not hurry, yet everything is accomplished.`,
        author: `Lao Tzu`
      },
      {
        quote: `Silence is a source of Great Strength.`,
        author: `Lao Tzu`
      },
      {
        quote: `When the going gets tough, the tough get going.`,
        author: `Joe Kennedy`
      },
      {
        quote: `You must be the change you wish to see in the world.`,
        author: `Gandhi`
      },
      {
        quote: `Tough times never last but tough people do.`,
        author: `Robert H. Schuller`
      },
      {
        quote: `Get busy living or get busy dying.`,
        author: `Stephen King`
      },
      {
        quote: `The only person you are destined to become is the person you decide to be.`,
        author: `Ralph Waldo Emerson`
      },
      {
        quote: `We have nothing to fear but fear itself.`,
        author: `Franklin D. Roosevelt`
      },
      {
        quote: `The only thing necessary for the triumph of evil is for good men to do nothing.`,
        author: `Edmund Burke`
      },
      {
        quote: `Knowing yourself is the beginning of all wisdom.`,
        author: `Aristotle`
      },
      {
        quote: `We are what we repeatedly do; excellence, then, is not an act but a habit.`,
        author: `Aristotle`
      },
      {
        quote: `Don’t count the days, make the days count.`,
        author: `Muhammad Ali`
      },
      {
        quote: `The successful warrior is the average man, with laser-like focus.`,
        author: `Bruce Lee`
      },
      {
        quote: `Holding onto anger is like drinking poison and expecting the other person to die.`,
        author: `Buddha`
      },
      {
        quote: `The further a society drifts from the truth, the more it will hate those that speak it.`,
        author: `George Orwell`
      },
      {
        quote: `Knowledge makes a man unfit to be a slave.`,
        author: `Frederick Douglas`
      },
      {
        quote: `All that we are is the result of what we have thought.`,
        author: `Buddha`
      },
      {
        quote: `There are two things a person should never be angry at, what they can help, and what they cannot.`,
        author: `Plato`
      },
      {
        quote: `Be kind, for everyone you meet is fighting a hard battle.`,
        author: `Plato`
      },
      {
        quote: `The quieter you become, the more you can hear.`,
        author: `Ram Dass`
      },
      {
        quote: `When you know how to listen everybody is the guru.`,
        author: `Ram Dass`
      },
      {
        quote: `We are all affecting the world every moment, whether we mean to or not. Our actions and states of mind matter, because we are so deeply interconnected with one another.`,
        author: `Ram Dass`
      },
      {
        quote: `Rome wasn't built in a day.`,
        author: `John Heywood`
      },
      {
        quote: `If, then, I were asked for the most important advice I could give, that which I considered to be the most useful to the men of our century, I should simply say: in the name of God, stop a moment, cease your work, look around you.`,
        author: `Leo Tolstoy`
      },
      {
        quote: `If the people in your circle aren't contributing to your growth, then you aren't in a circle, you're in a cage.`,
        author: `Kianu Starr`
      },
      {
        quote: `Awareness is like the sun. When it shines on things, they are transformed.`,
        author: `Thich Nhat Hanh`
      },
      {
        quote: `Our very awareness is the window upon which reality presents itself.`,
        author: `Frederick Lenz`
      },
      {
        quote: `Nations are not ruined by one act of violence, but gradually and in an almost imperceptible manner by the depreciation of their circulating currency, through excessive quantity`,
        author: `Nicholas Copernicus, 1525`
      },
      {
        quote: `The private sector of the economy is, in fact, the voluntary sector; and the public sector is, in fact, the coercive sector`,
        author: `Henry Hazlitt`
      },
      {
        quote: `Now I am quietly waiting for the catastrophe of my personality to seem beautiful again, and interesting and modern. The country is gray and brown and white and trees. Snows and skies of laughter are always diminishing. Less funny, not just darker. Not just gray. It may be the coldest day of the year. What does he think of that? I mean, what do I? And if I do, perhaps I am myself again.`,
        author: `Mad Men`
      },
      {
        quote: `It is the great parent of science and of virtue: and that a nation will be great in both, always in proportion as it is free.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `I predict future happiness for Americans, if they can prevent the government from wasting the labors of the people under the pretense of taking care of them.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `The man who reads nothing at all is better educated than the man who reads nothing but newspapers.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `On matters of style, swim with the current. On matters of principle, stand like a rock.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `If a nation expects to be ignorant and free, in a state of civilization, it expects what never was and never will be.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `I hold it that a little rebellion now and then is a good thing, and as necessary in the political world as storms in the physical. Unsuccesful rebellions indeed generally establish the incroachments on the rights of the people which have produced them. An observation of this truth should render honest republican governors so mild in their punishment of rebellions, as not to discourage them too much. It is a medecine necessary for the sound health of government.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `We hold these truths to be self-evident, that all men are created equal, that they are endowed by their Creator with certain unalienable Rights, that among these are Life, Liberty and the pursuit of Happiness.--That to secure these rights, Governments are instituted among Men, deriving their just powers from the consent of the governed, --That whenever any Form of Government becomes destructive of these ends, it is the Right of the People to alter or to abolish it, and to institute new Government.`,
        author: `Declaration of Independence, July 4th 1776`
      },
      {
        quote: `There are more instances of the abridgment of the freedom of the people by gradual and silent encroachments of those in power than by violent and sudden usurpations.`,
        author: `James Madison`
      },
      {
        quote: `Knowledge will forever govern ignorance, and a people who mean to be their own governors, must arm themselves with the power knowledge gives.`,
        author: `James Madison`
      },
      {
        quote: `It will be of little avail to the people that the laws are made by men of their own choice if the laws be so voluminous that they cannot be read, or so incoherent that they cannot be understood.`,
        author: `James Madison`
      },
      {
        quote: `Charity is no part of the legislative duty of the government.`,
        author: `James Madison`
      },
      {
        quote: `If Men were angels, no government would be necessary. If angels were to govern men, neither external nor internal controls on government would be necessary. In framing a government which is to be administered by men over men, the great difficulty lies in this: you must first enable the government to control the governed; and the next place, oblige it to control itself.`,
        author: `James Madison`
      },
      {
        quote: `History records that the money changers have used every form of abuse, intrigue, deceit, and violent means possible to maintain their control over governments by controlling money and it's issuance.`,
        author: `James Madison`
      },
      {
        quote: `Crisis is the rallying cry of the tyrant.`,
        author: `James Madison`
      },
      {
        quote: `If Tyranny and Oppression come to this land, it will be in the guise of fighting a foreign enemy.`,
        author: `James Madison`
      },
      {
        quote: `A bad cause seldom fails to betray itself.`,
        author: `James Madison`
      },
      {
        quote: `Democracy is the most vile form of government.`,
        author: `James Madison`
      },
      {
        quote: `Disarm the people -- that is the best and most effective way to enslave them.`,
        author: `James Madison`
      },
      {
        quote: `Liberty, once lost, is lost forever.`,
        author: `John Adams`
      },
      {
        quote: `These are the times that try men's souls.`,
        author: `Thomas Paine`
      },
      {
        quote: `The harder the conflict, the more glorious the triumph. What we obtain too cheap, we esteem too lightly.`,
        author: `Thomas Paine`
      },
      {
        quote: `Trust is a fragile thing. Hard to earn, easy to lose.`,
        author: `M.J. Arlidge`
      },
      {
        quote: `If ever a time should come, when vain and aspiring men shall possess the highest seats in Government, our country will stand in need of its experienced patriots to prevent its ruin.`,
        author: `Samuel Adams`
      },
      {
        quote: `Instead of cursing the darkness, light a candle.`,
        author: `Benjamin Franklin`
      },
      {
        quote: `I cried because I had no shoes until I met a man who had no feet.`,
        author: `Helen Keller`
      },
      {
        quote: `The best way to predict your future is to create it.`,
        author: `Abraham Lincoln`
      },
      {
        quote: `If you are distressed by anything external, the pain is not due to the thing itself, but to your estimate of it; and this you have the power to revoke at any moment.`,
        author: `Marcus Aurelius`
      },
      {
        quote: `You may delay, but time will not.`,
        author: `Benjamin Franklin`
      },
      {
        quote: `It is the peculiar quality of a fool to perceive the faults of others and to forget his own.`,
        author: `Cicero`
      },
      {
        quote: `In individuals, insanity is rare; but in groups, parties, nations, and epochs, it is the rule.`,
        author: `Nietzsche`
      },
      {
        quote: `Until you make the unconscious conscious, it will direct your life and you will call it fate.`,
        author: `Carl Jung`
      },
      {
        quote: `Knowing your own darkness is the best method for dealing with the darknesses of other people.`,
        author: `Carl Jung`
      },
      {
        quote: `Forgiveness is not so much about the other person as it is about our own hearts.`,
        author: `Anonymous`
      },
      {
        quote: `"The mind has no capacity to give or take away consciousness. It is merely one expression of consciousness. It's our over-involvement with mind activity - thoughts and emotions - that limits our consciousness to the mind. When we begin to become aware in consciousness beyond the mind we are awakening to our true self."`,
        author: `Xan`
      },
      {
        quote: `A quick temper will make a fool of you soon enough.`,
        author: `Bruce Lee`
      },
      {
        quote: `It is hard to imagine a more stupid or more dangerous way of making decisions than by putting those decisions in the hands of people who suffer no consequences for being wrong.`,
        author: `Thomas Sowell`
      },
      {
        quote: `The government is good at one thing. It knows how to break your legs, and then hand you a crutch and say, See- if it weren't for the government, you wouldn't be able to walk.`,
        author: `Harry Browne`
      },
      {
        quote: `Remember, democracy never lasts long. It soon wastes, exhausts, and murders itself. There never was a democracy yet that did not commit suicide.`,
        author: `John Adams`
      },
      {
        quote: `We live in confusion and in the illusion of things. There is a reality. You are that reality. When you know that you will know that you are nothing and in being nothing you are everything.`,
        author: `Kalu Rinpoche`
      },
      {
        quote: `The ruling class -- be it warlords, nobles, bureaucrats, feudal landlords, monopoly merchants, or a coalition of several of these groups -- must employ intellectuals to convince the majority of the public that its rule is beneficent, inevitable, necessary and even divine. The leading role of the intellectual throughout history is that of the court intellectual, who, in return for a share of a junior partnership in, the power and pelf offered by the rest of the ruling class, spins apologias for state rule with which to convince a misguided public. This is the age-old alliance of church and state, of throne and alter, with the church in modern times being largely replaced by secular intellectuals and 'scientific' technocrats.`,
        author: `Murray Rothbard`
      },
      {
        quote: `The somebody that you think you are is just a thought appearing in the consciousness that you are. For one instant. Less than an instant. Stop telling your story. Even if it’s a good story, just stop and immediately the truth is told. You cannot tell your story if you are telling the truth. And you cannot tell the truth if you are telling your story. `,
        author: `Gangaji`
      },
      {
        quote: `In silence there is eloquence. Stop weaving and see how the pattern improves.`,
        author: `Rumi`
      },
      {
        quote: `This is the difference between the power of darkness and the power of light. That you can go into a pitch black room, full of darkness, light a candle and instantly the darkness flees. But you can’t do the opposite.`,
        author: `Dr. Len Horowitz`
      },
      {
        quote: `The people who are trying to make this world worse are not taking a day off, how can I? Light up the darkness.`,
        author: `Bob Marley`
      },
      {
        quote: `Be the light that helps others see.`,
        author: `Anonymous`
      },
      {
        quote: `I would say that if we would have followed the advice of the founding fathers and not allowed this power structure, this group of elitist bankers and industrialists to get control of not only the banking system and the monetary system but really our foreign policy and our government…the control of overall policy is in the hands of a very small number of people who control all the administrations, all the appointments to cabinets, and certainly all the appointments to the federal reserve.`,
        author: `Congressman Ron Paul`
      },
      {
        quote: `They say we violate international law. Good they remember international law exists. Better late than never.`,
        author: `Vladimir Putin`
      },
      {
        quote: `To be a spiritual warrior, one must have a broken heart. Without a broken heart and the sense of tenderness and vulnerability, your warriorship is untrustworthy.`,
        author: `Chögyam Trungpa Rinpoche`
      },
      {
        quote: `Just when the caterpillar thought the world was over, it became a butterfly.`,
        author: `Chuang Tzu`
      },
      {
        quote: `Death is NOT the end, nor is it horrible, nor is it painful. The most important, vital, and powerful thing we all share is only felt a little here on earth--although I do believe we can develop it much more by being good humans.`,
        author: `My former dentist, Dr. Sharon Barton`
      },
      {
        quote: `The two enemies of the people are criminals and government, so let us tie the second down with the chains of the Constitution so the second will not become the legalized version of the first.`,
        author: `Thomas Jefferson`
      },
      {
        quote: `There is no matter as such. All matter originates and exists only by virtue of a force which brings the particle of an atom to vibration and holds this most minute solar system of the atom together. We must assume behind this force the existence of a conscious and intelligent mind. This mind is the matrix of all matter.`,
        author: `Max Planck, Nobel Prize winning physicist and founder of Quantum Physics`
      },
      {
        quote: `Do not try to bend the spoon, that's impossible. Instead, only try to realize the truth. There is no spoon. Then you'll see that it isn't the spoon that bends, it is only yourself.`,
        author: `The Matrix`
      },
      {
        quote: `If we are not able to ask skeptical questions--to interrogate those who tell us that something is true, to be skeptical of those in authority--then we’re up for grabs for the next charlatan, political or religious, who comes ambling along.  It’s a thing that Jefferson laid great stress on. It wasn’t enough, he said, to enshrine some rights in a Constitution or a Bill of Rights. The people had to be educated and they had to practice their skepticism and their education. Otherwise, we don’t run the government. The government runs us.`,
        author: `Carl Sagan`
      },
      {
        quote: `A patriot must always be ready to defend his country against its government.`,
        author: `Edward Abbey`
      }
  ];

  let randomNumber = Math.floor(Math.random()*quoteArr.length)


  return (
      <div className='quotes'>
          <figure className = "QuoteFig">
          <blockquote>"{quoteArr[randomNumber].quote}"</blockquote>
          <figcaption>- {quoteArr[randomNumber].author}</figcaption>
          </figure>
      </div>
      
  )
}

