import './App.css';
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom"
import { useContext } from 'react';
import { AuthContext } from './components/authContext';
import axios from 'axios';
import Login from "./pages/Login"
import Home from "./pages/Home"
import OurWorld from "./pages/OurWorld"
import Photos from "./pages/Photos"
import Write from "./pages/Write"
import Upload from "./pages/Upload"
import Merlin from "./pages/Merlin"
import Subscription from './pages/Subscription';
import ManageSub from './pages/ManageSub';
import Single from './pages/Single';
import SingleDraft from './pages/SingleDraft';
import Drafts from './pages/Drafts';
import WriteDraft from './pages/WriteDraft';
import Photo from './pages/Photo';
import Verify from './pages/Verify';
import NotFound from './pages/NotFound';
import MyLife from './pages/MyLife';
import Blogs from './pages/Blogs';
import NavBar from './components/NavBar';
import Contact from './pages/Contact';

axios.defaults.baseURL = "/api/"

// LAYOUT FOR USERS
const Layout = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <NavBar />
      <div className='container'>
        {!currentUser ? <Login /> : <Outlet />}
      </div>
    </>
  )
}
// LAYOUT FOR ADMIN
const PrivateLayout = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <NavBar />
      <div className='container'>
        {currentUser && currentUser === "admin" ? <Outlet /> : <Login />}
      </div>
    </>
  )
}
// LINKS FOR USERS
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/blogs",
        element: <Blogs />
      },
      {
        path: "/mylife",
        element: <MyLife />
      },
      {
        path: "/ourworld",
        element: <OurWorld />
      },
      {
        path: "/photos",
        element: <Photos />
      },
      {
        path: "/merlin",
        element: <Merlin />
      },
      {
        path: "/subscription",
        element: <Subscription />
      },
      {
        path: "/manage-subscription",
        element: <ManageSub />
      },
      {
        path: "/post/:id",
        element: <Single />
      },
      {
        path: "/photos",
        element: <Photos />
      },
      {
        path: "/photo/:id",
        element: <Photo />
      },
      {
        path: "/verify/:code",
        element: <Verify />
      },
      {
        path: "/404",
        element: <NotFound />
      },
      {
        path: "/contact",
        element: <Contact />
      },
    ]
  },
  // LINKS FOR ADMIN
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/blogs",
        element: <Blogs />
      },
      {
        path: "/mylife",
        element: <MyLife />
      },
      {
        path: "/ourworld",
        element: <OurWorld />
      },
      {
        path: "/photos",
        element: <Photos />
      },
      {
        path: "/merlin",
        element: <Merlin />
      },
      {
        path: "/write",
        element: <Write />
      },
      {
        path: "/upload",
        element: <Upload />
      },
      {
        path: "/subscription",
        element: <Subscription />
      },
      {
        path: "/manage-subscription",
        element: <ManageSub />
      },
      {
        path: "/post/:id",
        element: <Single />
      },
      {
        path: "/draft/:id",
        element: <SingleDraft />
      },
      {
        path: "/drafts",
        element: <Drafts />
      },
      {
        path: "/writedraft",
        element: <WriteDraft />
      },
      {
        path: "/photos",
        element: <Photos />
      },
      {
        path: "/upload",
        element: <Upload />
      },
      {
        path: "/photo/:id",
        element: <Photo />
      },
      {
        path: "/verify/:code",
        element: <Verify />
      },
      {
        path: "/404",
        element: <NotFound />
      },
      {
        path: "/contact",
        element: <Contact />
      },
    ]
  },
  // LINKS FOR NON-USERS
  {
    path: "/login",
    element: <Login />,
  }
]);


export default function App() {

  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
}

