import "../css/subscription.css"
import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from "../img/logo.png"

export default function Subscription() {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [mylife, setMyLife] = useState(null)
  const [ourworld, setTheworld] = useState(null)
  const [err, setErr] =useState(null)
  const [msg, setMsg] = useState(null)

  const navigate = useNavigate();
  //MAKE SURE AT LEAST 1 CHECKBOX IS TICKED
  // const checkBoxes = document.querySelectorAll('input[type=checkbox]:checked')
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if(!mylife && !ourworld){
        setErr("Choose at least one")
      }
      else{
        const code = Math.random().toString(36).slice(-8);
        await axios.post(`/subs`, {
          name: name,
          email: email,
          mylife: mylife,
          ourworld: ourworld,
          verified: false,
          code: code
        });
        setMsg("Verification email sent")
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    }catch(err){
      if (err.response && err.response.data && err.response.data.code){
        if(err.response.data.code === 'ER_DUP_ENTRY'){
          setErr("Email Already Exists")
        }
        else{
          setErr(err.response.data.code)
        }
      } else {
        setErr("Cannot subscribe, error occured")
      }
    }
  }
  return (
    <div className="subscription">
      <h1>Be notified when the blog is updated</h1>
      <form className="sub-form" onSubmit={handleSubmit}>
        <img className="sub-img" src={logo} alt=""></img>
        <div className="sub-inputs">
          <input
            className="sub-name"
            aria-label="Your first name"
            name="fields[first_name]"
            placeholder="Your First Name"
            required
            type="text"
            onChange={e=>setName(e.target.value)}
            value={name}
          />
          <input
            className="sub-email"
            aria-label="Your email address"
            name="email_address"
            placeholder="Your Email Address"
            required
            type="email"
            onChange={e=>setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="sub-checkboxes">
          <p>Notify me of the following:</p>
          <input type="checkbox" value={true} name="MyLife Blog" id="MyLife Blog" onChange={e=>setMyLife(e.target.checked)}/>
          <label htmlFor='MyLife Blog'>My Life</label>
          <input type='checkbox'value={true} name='Our World Blog' id='Our World Blog' onChange={e=>setTheworld(e.target.checked)}/>
          <label htmlFor='Our World Blog'>Our World</label> 
        </div>
        <button className="sub-button" onSubmit={handleSubmit}>SUBSCRIBE</button>  
        {msg && <p className="sub-error">{msg}</p>}
        {err && <p className="sub-error">{err}</p>}
      </form>
    </div>
  )
}
