import "../css/drafts.css"
import axios from "axios";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";

export default function Drafts() {

    const [drafts, setDrafts] = useState([]);
    
    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                const res = await axios.get("/drafts")
                setDrafts(res.data)
            }catch(err){
                console.error(err.response.data)
            }
        };
        fetchData();
    },[])

    return (
        <div className="drafts">
            <h1>Drafts</h1>
            {drafts.map(draft=>(
                <div className="draft-list" key={draft.id}>
                    <Link className="drafts-link" to={`/draft/${draft.id}`}>
                        <p>{draft.title}</p>
                    </Link>
                </div>
            ))}
        </div>
    )
}
