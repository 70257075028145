import React from 'react'
import "../css/contact.css"

export default function Contact() {
  return (
    <div className='contact'>
        <h1>Write me at james@jamesneto.com</h1>
    </div>
  )
}
